import React from "react";
import { Link } from "react-router-dom";
import aboutUsImage from "../../assets/about-us.png";

const AboutBar = () => {
  return (
    <div>
      <section className="bg-[#CAA84E] py-8 w-full">
        <div className="max-w-4xl mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4">
            <h3 className="text-2xl md:text-4xl text-white font-bold mb-4">
              About us
            </h3>
            <p className="mb-4 text-sm md:text-lg text-white ">
              At De Happy Planners, we are dedicated to crafting unforgettable
              experiences that exceed expectations. With a passion for
              creativity and a commitment to excellence, we specialize in
              bringing your events to life.
            </p>
            <Link
              to="/about-us"
              className="bg-[#51473E] text-white p-2 md:p-3 rounded-lg"
            >
              View More
            </Link>
          </div>
          {/* <div className="h-[5rem] w-[10rem] md:h-[10rem] md:w-[32rem] border"></div> */}
          <img src={aboutUsImage} alt="" className="w-[20rem]" />
        </div>
      </section>
    </div>
  );
};

export default AboutBar;
