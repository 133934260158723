import { useEffect, useRef, useState } from "react";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa6";

const Footer = () => {
  const emailRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const descriptionRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await emailjs.send("service_ftd65s4", "template_mgp5d9d", {
        from_name: firstNameRef.current.value,
        recipient: emailRef.current.value,
        to_name: "De Happy Planners",
        from_firstName: firstNameRef.current.value,
        message: descriptionRef.current.value,
        from_phoneNumber: emailRef.current.value,
      });
      toast.success("sent successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => emailjs.init("PrgvwM48UtO5FG4qc"), []);

  return (
    <div>
      <footer className="bg-black py-8 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="text-white mb-6 p-10">
            <h3 className="text-xl font-bold mb-4">About us</h3>
            <p className="text-sm sm:text-lg text-[#f1f1f1]">
              At De Happy Planners, we are dedicated to crafting unforgettable
              experiences that exceed expectations. With a passion for
              creativity and a commitment to excellence, we specialize in
              bringing your events to life.
            </p>

            <div className="hidden md:mt-5 sm:flex flex-col gap-2">
              <h2 className="text-xl font-bold mb-1">Contact us</h2>
              <div className="space-y-2">
                <Link
                  to={"https://wa.me/31639563567"}
                  className="flex gap-2 items-center"
                >
                  <FaWhatsapp className="size-5" />
                  +31 639563567
                </Link>
                <Link
                  to="mailto:dehappyplanners@gmail.com"
                  className="flex gap-2 items-center"
                >
                  <IoMailOutline className="size-5" />
                  dehappyplanners@gmail.com
                </Link>

                <Link
                  to={"https://www.instagram.com/dehappyplanners"}
                  className="flex gap-2 items-center"
                >
                  Follow us on <FaInstagram className="size-5" />
                  @dehappyplanners
                </Link>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="grid grid-cols-2 gap-4 sm:mb-4  p-10"
          >
            <input
              ref={firstNameRef}
              type="text"
              placeholder="First name"
              className="p-3 rounded-lg"
            />
            <input
              ref={lastNameRef}
              type="text"
              placeholder="Last name"
              className="p-3 rounded-lg"
            />
            <input
              ref={emailRef}
              type="email"
              placeholder="E-mail"
              className="p-3 rounded-lg"
            />
            <input
              ref={phoneNumberRef}
              placeholder="Phone number"
              className="p-3 rounded-lg"
            />
            <textarea
              ref={descriptionRef}
              className="col-span-2 p-3 rounded-lg"
              placeholder="Description"
            />
            <button
              disabled={loading}
              className="col-span-2 bg-[#FFCF6F] p-3 rounded-lg text-black"
            >
              {loading ? (
                <FaSpinner className="animate-spin mx-auto" />
              ) : (
                "Submit"
              )}
            </button>
          </form>

          <div className="flex sm:hidden text-white w-full p-10 flex-col gap-2">
            <h2 className="text-xl font-bold mb-1">Contact us</h2>
            <div className="space-y-2">
              <Link
                to={"https://wa.me/31639563567"}
                className="flex gap-2 items-center"
              >
                <FaWhatsapp className="size-5" />
                +31 639563567
              </Link>
              <Link
                to="mailto:dehappyplanners@gmail.com"
                className="flex gap-2 items-center"
              >
                <IoMailOutline className="size-5" />
                dehappyplanners@gmail.com
              </Link>

              <Link
                to={"https://www.instagram.com/dehappyplanners"}
                className="flex gap-2 items-center"
              >
                Follow us on <FaInstagram className="size-5" />
                @dehappyplanners
              </Link>
            </div>
          </div>
        </div>

        <p className="text-white text-sm text-center">copyright@2024</p>
      </footer>
    </div>
  );
};

export default Footer;
