import weddingPlannerImage from "../../assets/wedding-planner-services.png";
import birthdayPartyImage from "../../assets/birthday-parties-services.png";
import communityEventImage from "../../assets/community-event-services.png";
import effectiveServicesImage from "../../assets/effective-service.png";

const ServicesSection = () => {
  return (
    <div>
      <section className="py-8">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-2xl md:text-5xl font-poppins text-[#B8994B] font-bold text-start mb-6 mx-auto">
            Services
          </h2>

          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-y-8 gap-x-4 md:gap-x-20">
            <div className="flex flex-col items-center border shadow-md">
              <img
                src={weddingPlannerImage}
                alt=""
                className="aspect-square w-full object-cover"
              />

              <p className="p-3 mt-8 text-[#B8994B] justify-self-end ">
                Wedding Planner
              </p>
            </div>
            <div className=" flex flex-col items-center border shadow-md">
              <img
                src={birthdayPartyImage}
                alt=""
                className="aspect-square w-full object-cover"
              />

              <personalbar className="p-3 mt-8 text-[#B8994B] justify-self-end">
                Birthday Parties
              </personalbar>
            </div>
            <div className="  flex flex-col items-center border shadow-md">
              <img
                src={communityEventImage}
                alt=""
                className="aspect-square w-full object-cover"
              />

              <div className="p-3 mt-8 text-[#B8994B] justify-self-end">
                Community events
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8">
        <div className="max-w-4xl mx-auto px-4  flex justify-between items-center flex-col md:flex-row ">
          <img src={effectiveServicesImage} alt="" className=" md:w-[25rem]" />

          <div className="p-10 flex-1">
            <h3 className="text-xl md:text-4xl text-[#B8994B] font-bold mb-4">
              Effective service
            </h3>
            <p className="mb-4 text-sm md:text-lg text-[#7A7A7A]">
              De happy planner specializes in creating memorable and engaging
              experiences that leave a lasting impression on our clients and
              their guests. We understand the importance of every detail and
              work tirelessly to ensure that everything runs seamlessly from
              start to finish.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesSection;
