import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-black">
      <div className="">
        <div className="flex justify-between items-center bg-black p-4">
          <Link to="/" className="flex flex-shrink-0">
            <img
              src={logo}
              alt="logo"
              className="h-20 md:h-24 w-32 object-contain mx-3"
            />
          </Link>
          <div className="hidden w-full justify-center md:flex space-x-20">
            <Link to="/" className="text-white">
              Home
            </Link>
            <Link to="/about-us" className="text-white">
              About us
            </Link>
            <Link to="/materials-rental" className="text-white">
              Materials Rental
            </Link>
            <Link to="/contact" className="text-white">
              Contact
            </Link>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white hover:text-gray-300 focus:outline-none focus:text-gray-300"
            >
              <svg
                className="h-6 w-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 18L18 6M6 6l12 12"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 6h16M4 12h16m-7 6h7"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          className={`md:hidden ${
            isOpen ? "block" : "hidden"
          } transition-all duration-500 `}
        >
          <Link to="/" className="block text-white py-2 px-4">
            Home
          </Link>
          <Link to="/about-us" className="block text-white py-2 px-4">
            About us
          </Link>
          <Link to="/materials-rental" className="block text-white py-2 px-4">
            Materials Rental
          </Link>
          <Link to="/contact" className="block text-white py-2 px-4">
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
