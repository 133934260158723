import image from "../../assets/wedding-img.jpeg";
import ContactSection from "../Contact/ContactSection";

const Contact = () => {
  return (
    <div>
      <img
        src={image}
        alt="Event Imgage"
        className="w-full min-h-72"
        height="600"
        style={{
          aspectRatio: "1920/600",
          objectFit: "cover",
        }}
        width="1920"
      />
      <ContactSection />
    </div>
  );
};

export default Contact;
