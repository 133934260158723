import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Materials from "./components/Materials/Materials";

function App() {
  return (
    <div className="">
      <Routes>
        <Route index element={<Home />} path="/" />
        <Route element={<About />} path="/about-us" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Materials />} path="/materials-rental" />
      </Routes>
    </div>
  );
}

export default App;
