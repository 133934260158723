import React from "react";
import showcaseImage from "../../assets/showcase.png";
import Contact from "../Layout/Contact";

const AboutSection = () => {
  return (
    <div>
      <main className="container max-w-4xl mx-auto px-4 py-8">
        <section className="my-12 flex flex-col gap-16">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl md:text-5xl text-[#B8994B] font-bold text-start mb-6 mx-auto">
              De happy planners
            </h2>

            <p className="text-[#7A7A7A] text-center text-xs md:text-sm">
              At De Happy Planners, we are dedicated to crafting unforgettable
              experiences that exceed expectations. With a passion for
              creativity and a commitment to excellence, we specialize in
              bringing your events to life. From corporate gatherings to
              weddings, birthday parties, community events, we handle every
              detail with precision and care, ensuring a seamless and
              stress-free experience for our clients.
            </p>
          </div>

          <div className="flex flex-col gap-2">
            <h2 className="text-2xl md:text-5xl text-[#B8994B] font-bold text-start mb-6 mx-auto">
              Our Mission
            </h2>

            <p className="text-[#7A7A7A] text-center text-xs md:text-sm">
              At De Happy Planners, we are dedicated to crafting unforgettable
              experiences that exceed expectations. With a passion for
              creativity and a commitment to excellence, we specialize in
              bringing your events to life. From corporate gatherings to
              weddings, birthday parties, community events, we handle every
              detail with precision and care, ensuring a seamless and
              stress-free experience for our clients.
            </p>
          </div>

          {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-sm md:text-lg">
            <p className="text-[#7A7A7A]">
              Als u op zoek bent naar creativiteit en een energieke invulling
              voor uw bruiloft of evenementen, dan hebt u aan mij een goede
              weddingplanner. Ik doe wat u verwacht en meer dan u had gehoopt.
            </p>
            <p className="text-[#7A7A7A]">
              Achter de schermen van Diamond weddingplanner wordt dat
              gerealiseerd door Elham Klaassens, gediplomeerd weddingplanner.
            </p>
            <p className="text-[#7A7A7A]">
              "Mijn kinderen zijn groter geworden en ik heb meer tijd gekregen
              voor andere uitdagingen in mijn leven. Het moest iets anders
              worden dan wat ik al gedaan had en vooral bij mijn persoonlijke
              eigenschappen passen. Ik ben nogal secuur, vasthoudend en altijd
              op zoek naar passende en originele oplossingen voor als het anders
              verloopt dan verwacht. Daarnaast vind ik begrippen als stijlvol,
              chique en blijdschap heel belangrijk. Het werd weddingplanner".
            </p>
            <p className="text-[#7A7A7A]">
              Ik heb de opleiding tot weddingplanner met veel plezier gevolgd en
              voor praktijkopdrachten heb ik altijd hoge beoordelingen gekregen.
              Ik weet hoe belangrijk het is om mijzelf te kunnen zijn. Datzelfde
              gevoel wil ik ook bij jullie proberen te bewerkstelligen en helpen
              keuzes te maken die echt bij jullie passen.
            </p>
          </div> */}
        </section>
        <div className="my-16">
          <img src={showcaseImage} alt="" className="w-full" />
        </div>
        {/* <section className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 rounded-xl bg-[#CAA84E] p-8 mb-16">
          <div className="flex-1">
            <h3 className="text-2xl font-bold mb-4 text-white">About</h3>
            <p className="text-[#fff] text-sm md:text-lg">
              Als u op zoek bent naar creativiteit en een energieke invulling
              voor uw bruiloft of evenementen, dan hebt u aan mij een goede
              wedding & party planner. Ik doe wat u verwacht en meer dan u hebt
              gehoopt.
            </p>
          </div>
          <button className="bg-[#4b4b4b] p-2 rounded-lg text-white">
            Contact
          </button>
        </section> */}

        <Contact />
      </main>
    </div>
  );
};

export default AboutSection;
