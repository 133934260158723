import image from "../../assets/home-main.png";
import ServicesSection from "../Home/ServicesSection";
import AboutBar from "../Home/AboutBar";

const Home = () => {
  return (
    <div>
      <img
        src={image}
        alt="Event Imgage"
        className="w-full min-h-72"
        height="600"
        style={{
          aspectRatio: "1920/600",
          objectFit: "cover",
        }}
        width="1920"
      />
      <ServicesSection />
      <AboutBar />
    </div>
  );
};

export default Home;
