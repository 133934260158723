import React from "react";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  return (
    <section className=" my-16 w-full text-white rounded-lg p-5 md:p-7 flex items-center justify-center bg-gradient-to-b from-[#d1a948] to-[#786125]  ">
      <div className="flex-1">
        <h1 className="text-center text-xl md:text-4xl font-bold">
          Get in touch with us
        </h1>
      </div>

      <button
        onClick={() => navigate("/contact")}
        className="bg-[#51473e] max-md:text-xs rounded-md py-3 px-3 md:px-5"
      >
        {" "}
        CONTACT
      </button>
    </section>
  );
};

export default Contact;
