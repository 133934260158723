import React from "react";
import sliderImage from "../../assets/slider-image.jpeg";
import { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa6";

const ContactSection = () => {
  const emailRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const descriptionRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await emailjs.send("service_ftd65s4", "template_mgp5d9d", {
        from_name: firstNameRef.current.value,
        recipient: emailRef.current.value,
        to_name: "De Happy Planners",
        from_firstName: firstNameRef.current.value,
        message: descriptionRef.current.value,
        from_phoneNumber: emailRef.current.value,
      });
      toast.success("sent successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => emailjs.init("PrgvwM48UtO5FG4qc"), []);

  return (
    <div>
      <div className="max-w-6xl mx-auto px-4 py-8">
        <section className=" px-4 mb-10 sm:mb-20">
          <h2 className="text-2xl md:text-4xl text-[#CAA84E] font-bold mb-4">
            Contact us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-sm md:text-lg">
            <div>
              <div className="space-y-4">
                <div className="">
                  <p className="mb-2 font-semibold">Contact Person:</p>
                  <ul className="text-[#7A7A7A] space-y-2">
                    <li>De Happy Planners</li>
                    <li>90497449</li>
                    <li>Email: dehappyplanners@gmail.com</li>
                  </ul>
                </div>
                <div className="">
                  <p className="mb-2 font-semibold">Address:</p>
                  <ul className="text-[#7A7A7A] space-y-2">
                    <li>Heksenberg 24,</li>
                    <li>5508AD Veldhoven </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <iframe
                title="Heksenberg"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2487.9581475903597!2d5.385608293104585!3d51.422195444204625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6da455ad1b0cf%3A0x556f4144c15756fb!2sHeksenberg%2024%2C%205508%20AD%20Veldhoven%2C%20Netherlands!5e0!3m2!1sen!2sin!4v1713246518412!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{
                  border: 0,
                }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>{" "}
            </div>
          </div>
        </section>
        <section className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="">
            <h2 className="text-2xl md:text-5xl text-[#CAA84E] font-bold">
              Contact form
            </h2>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-2 gap-4 mb-6 pt-8"
            >
              <input
                ref={firstNameRef}
                type="text"
                placeholder="First name"
                className="p-3 rounded-lg border"
              />
              <input
                ref={lastNameRef}
                type="text"
                placeholder="Last name"
                className="p-3 rounded-lg border"
              />
              <input
                ref={emailRef}
                type="email"
                placeholder="E-mail"
                className="p-3 rounded-lg border"
              />
              <input
                ref={phoneNumberRef}
                placeholder="Phone number"
                className="p-3 rounded- border"
              />
              <textarea
                ref={descriptionRef}
                className="col-span-2 p-3 rounded-lg border"
                placeholder="Description"
              />
              <button
                disabled={loading}
                className="col-span-2 bg-black p-3 rounded-lg text-white text-center"
              >
                {loading ? (
                  <FaSpinner className="animate-spin mx-auto" />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
          <div className="">
            <img
              src={sliderImage}
              alt="Slider"
              className="w-full"
              height="300"
              style={{
                aspectRatio: "600/300",
                objectFit: "cover",
              }}
              width="600"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactSection;
