import renatal1 from "../../assets/rental-1.png";
import renatal2 from "../../assets/rental-2.png";
import renatal3 from "../../assets/rental-3.png";
import { FaPhone } from "react-icons/fa6";
import Contact from "../Layout/Contact";
import { Link } from "react-router-dom";

const MaterialsSection = () => {
  return (
    <div>
      <main className="container max-w-4xl mx-auto px-4 py-8">
        <section className="w-full my-12 grid grid-cols-2 md:grid-cols-3 gap-8">
          <div className="relative flex flex-col shadow-md border">
            <span className="absolute top-3 right-0 py-2 px-3 bg-black text-white text-sm">
              DE101
            </span>

            <img src={renatal1} alt="" className="" />

            <div className="flex mt-5 p-3 justify-between items-center ">
              <Link
                to="https://wa.me/31639563567"
                className="py-2 px-3 bg-[#ffcf6f] max-sm:text-[10px] text-xs font-bold rounded-md"
              >
                SEND A MESSAGE
              </Link>
              <Link to={"tel:+31639563567"}>
                <FaPhone className=" max-sm:size-4 size-5" />
              </Link>
            </div>
          </div>
          <div className="relative flex flex-col shadow-md border">
            <span className="absolute top-3 right-0 py-2 px-3 bg-black text-white text-sm">
              DE102
            </span>
            <img src={renatal2} alt="" className="" />

            <div className="flex mt-5 p-3 justify-between items-center ">
              <Link
                to="https://wa.me/31639563567"
                className="py-2 px-3 bg-[#ffcf6f] max-sm:text-[10px] text-xs font-bold rounded-md"
              >
                SEND A MESSAGE
              </Link>
              <Link to={"tel:+31639563567"}>
                <FaPhone className=" max-sm:size-4 size-5" />
              </Link>
            </div>
          </div>
          <div className="relative flex flex-col shadow-md border">
            <span className="absolute top-3 right-0 py-2 px-3 bg-black text-white text-sm">
              DE103
            </span>
            <img src={renatal3} alt="" className="" />

            <div className="flex mt-5 p-3 justify-between items-center">
              <Link
                to="https://wa.me/31639563567"
                className="py-2 px-3 bg-[#ffcf6f] max-sm:text-[10px] text-xs font-bold rounded-md"
              >
                SEND A MESSAGE
              </Link>
              <Link to={"tel:+31639563567"}>
                <FaPhone className=" max-sm:size-4 size-5" />
              </Link>
            </div>
          </div>
        </section>

        <Contact />
      </main>
    </div>
  );
};

export default MaterialsSection;
