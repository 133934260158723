import aboutImage from "../../assets/about.jpeg";
import AboutSection from "../About/AboutSection";

const About = () => {
  return (
    <div>
      <header className="relative">
        <img
          src={aboutImage}
          alt="about us"
          className="w-full h-auto object-cover min-h-72"
          height="600"
          style={{
            aspectRatio: "1920/600",
            objectFit: "cover",
          }}
          width="1920"
        />
        <h1 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-4xl md:text-7xl font-black text-border">
          About us
        </h1>
      </header>

      <AboutSection />
    </div>
  );
};

export default About;
